body {
  font-family: sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pokemons {
  width: 70%;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
}
.paginate {
  text-align: center;
  padding-bottom: 30px;
}
.paginate > a {
  font-weight: bold;
  font-size: 1.2rem;
  margin: 0 5px;
}
.pokemon {
  width: 300px;
  height: 300px;
  border-radius: 9px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.4);
  transition: box-shadow ease-in-out 200ms, transform ease-in-out 200ms;
  padding: 10px;
  cursor: pointer;
  margin: 20px 80px;
  text-align: center;
}
.pokemon > div > h3 {
  margin-bottom: 5px;
}
.pokemon > div > p {
  font-size: 0.8rem;
  margin: 0;
}
.pokemon:hover {
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.4);
  transform: scale(1.1);
}

.pokemon_image > img {
  width: 200px;
  height: 200px;
}

.grass {
  background: rgba(0, 128, 0, 0.4);
}
.fire {
  background: rgb(255, 0, 0, 0.4);
}
.water {
  background: rgba(0, 0, 255, 0.4);
}
.bug {
  background: rgba(144, 238, 144, 0.4);
}
.normal {
  background: rgba(255, 160, 122, 0.4);
}
.poison {
  background: rgba(255, 105, 180, 0.4);
}
.electric {
  background: rgba(255, 255, 0, 0.4);
}
.ground {
  background: rgba(222, 184, 135, 0.4);
}
.fairy {
  background: rgba(255, 192, 203, 0.4);
}
.fighting {
  background: rgba(255, 165, 0, 0.4);
}
.psychic {
  background: rgba(216, 112, 147, 0.4);
}
.dark {
  background: rgba(78, 9, 78, 0.4);
}
.rock {
  background: rgba(165, 42, 42, 0.4);
}
.ghost {
  background: rgba(128, 0, 128, 0.4);
}
.ice {
  background: rgba(173, 216, 230, 0.4);
}
.steel {
  background: rgb(128, 128, 128, 0.4);
}

@media only screen and (max-width: 600px) {
  .pokemons {
    width: 90%;
  }
  .pokemon {
    margin: 20px 0;
  }
}
